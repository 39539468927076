<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('商品分类.分类名称')" prop="categoryName">
                <a-input v-model="queryParam.categoryName" @pressEnter="handleQuery" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('商品分类.分类名称')" allow-clear/>
              </a-form-item>
            </a-col>
<!--            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('商品分类.上级分类')" prop="pid">
                <a-input v-model="queryParam.pid" :placeholder="$t('通用.输入.请输入')+$t('商品分类.上级分类')" allow-clear/>
              </a-form-item>
            </a-col>-->
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('商品分类.是否显示')" prop="isVisible">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('商品分类.是否显示')" style="width: 100%" v-model="queryParam.isVisible" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type+''"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('商品分类.是否首页展示')" prop="isHomeVisible">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('商品分类.是否首页展示')" style="width: 100%" v-model="queryParam.isHomeVisible" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type+''"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['goods:category:add']">
          <a-icon type="plus" />{{$t('通用.按钮.新增')}}
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['goods:category:edit']">
          <a-icon type="edit" />{{$t('通用.按钮.修改')}}
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['goods:category:remove']">
          <a-icon type="delete" />{{$t('通用.按钮.删除')}}
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['goods:category:export']">
          <a-icon type="download" />{{$t('通用.按钮.导出')}}
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        @change="handleSortChange"
        :expandedRowKeys="expandedKeys"
        @expand="onExpandCurrent"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="expandedRowRender" slot-scope="text">
          <category-sub-list ref="subList" :id="text.id" :level="text.level" />
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <template slot="categoryPic" slot-scope="text, record">
          <div>
            <img v-if="record.categoryPic" :src="record.categoryPic"  style="width:60px;height:62px;" @click="handleRecordPreview(record.categoryPic)" alt="none"/>
          </div>
        </template>
        <span slot="isFilter" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isFilter"/>
        </span>
        <span slot="isHomeVisible" slot-scope="text, record">
          <a-switch @change="(checked) => onHomeVisibleChange(checked,record)" :checked-children="$t('通用.文本.是')" :un-checked-children="$t('通用.文本.否')" :checked="record.isHomeVisible"/>
        </span>
        <span slot="isVisible" slot-scope="text, record">
          <a-switch @change="(checked) => onVisibleChange(checked,record)" :checked-children="$t('通用.文本.是')" :un-checked-children="$t('通用.文本.否')" :checked="record.isVisible"/>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['goods:category:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['goods:category:edit']">
             <a-icon type="edit" />{{$t('通用.按钮.修改')}}
          </a>
          <a-divider type="vertical" v-hasPermi="['goods:category:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['goods:category:remove']">
            <a-icon type="delete" />{{$t('通用.按钮.删除')}}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>

</template>

<script>
import {pageCategory, listCategory, delCategory, updateCategory, updateVisible} from '@/api/goods/category';
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import CreateForm from './modules/CreateForm'
import CategorySubList from './subList'
import { tableMixin } from '@/store/table-mixin'
import bus from "@/utils/bus";

export default {
  name: 'Category',
  components: {
    CustomDictTag,
    CategorySubList,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      selectedRowKeys: [],
      selectedRows: [],
      filteredInfo: {},
      sortedInfo : {},
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      expandedKeys: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        categoryName: null,
        categoryPic: null,
        pid: null,
        level: 1,
        isVisible: undefined,
        isHomeVisible: undefined,
        sort: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('商品分类.分类名称'),
          dataIndex: 'categoryName',
          width: '15%',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商品分类.分类图片'),
          dataIndex: 'categoryPic',
          scopedSlots: { customRender: 'categoryPic' },
          align: 'center'
        },
        {
          title: this.$t('商品分类.上级分类'),
          dataIndex: 'parentCategoryName',
          align: 'center'
        },
        {
          title: this.$t('商品分类.分类等级'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'level',
          align: 'center'
        },
        {
          title: this.$t('商品分类.是否显示'),
          dataIndex: 'isVisible',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'isVisible' },
          align: 'center'
        },
        {
          title: this.$t('商品分类.是否首页展示'),
          dataIndex: 'isHomeVisible',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'isHomeVisible' },
          align: 'center'
        },
        {
          title: this.$t('通用.文本.排序'),
          dataIndex: 'sort',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title:  this.$t('通用.文本.创建时间'),
          dataIndex: 'createTime',
          sorter: 1,
          ellipsis: true,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '12%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()

  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询商品分类列表 */
    getList () {
      this.loading = true
     pageCategory(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
      // 去更新列表页的数据
      bus.$emit('updateCateChildrenList')
    },
    onExpandCurrent(expandedKeys, row) {
      if (expandedKeys) {
        this.expandedKeys = [row.id]
      } else {
        this.expandedKeys = []
      }
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        categoryName: undefined,
        categoryPic: undefined,
        pid: undefined,
        level: undefined,
        isVisible: undefined,
        isHomeVisible: undefined,
        sort: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    onHomeVisibleChange(checked, record) {
      let data = {id:record.id, isHomeVisible: checked}
      updateVisible(data).then(response => {
          if (response.success) {
            this.$message.success(
              this.$t('通用.文本.修改成功'),
              3
            );
            this.$emit('ok')
            record.isHomeVisible=checked
          }
      }).finally(() => {
        this.submitLoading = false
      })
    },
    onVisibleChange(checked, record) {
      let data = {id:record.id, isVisible: checked}
      updateVisible(data).then(response => {
        if (response.success) {
          this.$message.success(
            this.$t('通用.文本.修改成功'),
            3
          );
          this.$emit('ok')
          record.isVisible=checked
        }
      }).finally(() => {
        this.submitLoading = false
      })
    },

    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      const names = row.name
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delCategory(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                that.$t('通用.文本.删除成功'),
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('goods/category/export', {
            ...that.queryParam
          }, `商品分类_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style lang="less">
.imgbox {
  overflow: hidden;
  background-color: #f7f7f7;
  position: relative;
  cursor: pointer;

  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.videomask {
  position: absolute;
  z-index: 10;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  line-height: 100px;
  background: rgba(0, 0, 0, .3);
  text-align: center;

  .icon {
    display: inline-block;
    font-size: 26px !important;
    color: #fff !important;
  }
}
</style>
